import { createStyles, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import Logo from "../../particles/Logo/Logo"

const Header = () => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.header)}>
      <Logo />
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      top: 0,
      height: 72,
      width: "100%",
      position: "fixed",
      paddingTop: 20,
      background: "#fafafa",
    },
  })
)

export default Header
