import React from "react"
import { Link } from "react-router-dom"
import "./messageStyles.css"

type MessageProps = {
  title: string
  text: string
  link: string
  linkText: string
}

const Message = ({ title, text, link, linkText }: MessageProps) => {
  return (
    <div className="card">
      <div>
        <h2>{title}</h2>
      </div>
      <div>{text}</div>
      <div>
        <Link to={link}>{linkText}</Link>
      </div>
    </div>
  )
}

export default Message
