import { AxiosResponse } from "axios"
import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { LoginState } from "../constants"
import { sessionState } from "../store"
import { LoginData, LoginResponse } from "../types/types"
import api from "../utils/api"
import { useStorageStore } from "./useStorageStore"

const loginResponseToLoginData = (loginResponse: LoginResponse): LoginData => {
  return {
    loginState: LoginState.SUCCESS,
    accessToken: loginResponse.tokens.accessToken,
    accessTokenExp: loginResponse.tokens.accessTokenExp,
    refreshToken: loginResponse.tokens.refreshToken,
    refreshTokenExp: loginResponse.tokens.refreshTokenExp,
    user: loginResponse.user,
  }
}

export const useSessionStore = () => {
  const [session, setSession] = useRecoilState(sessionState)
  const storageStore = useStorageStore()

  return {
    session,
    login: useCallback(
      async (
        username: string,
        password: string
      ): Promise<AxiosResponse<LoginResponse>> => {
        const apiReturn = await api.login(username, password)
        // This means login was valid.
        if (apiReturn.status === 201) {
          const loginData = loginResponseToLoginData(apiReturn.data)
          storageStore.setLoginData(loginData)
          setSession(loginData)

          window.sessionStorage.setItem("userName", apiReturn.data.user.email)
          window.sessionStorage.setItem("orgName", apiReturn.data.user.org.name)
        }
        return apiReturn
      },
      [storageStore, setSession]
    ),
    logOut: useCallback(async () => {
      sessionStorage.clear()
      storageStore.removeLoginData()
      setSession(null)
      window.location.href = "/"
    }, [storageStore, setSession]),

    verifyEmail: useCallback(
      async (token: string) => {
        const apiReturn = await api.verifyEmail(token)
        if (apiReturn.status < 300) {
          const loginData = loginResponseToLoginData(apiReturn.data)
          storageStore.setLoginData(loginData)
          setSession(loginData)

          window.sessionStorage.setItem("userName", apiReturn.data.user.email)
          window.sessionStorage.setItem("orgName", apiReturn.data.user.org.name)
        }
        return apiReturn
      },
      [storageStore, setSession]
    ),
  }
}
