import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"
import Logo from "../../particles/Logo/Logo"

const NotFound = () => {
  const styles = useStyles()

  return (
    <div className={styles.notFound}>
      <Logo />
      <h1>Something went wrong...</h1>
      <div>Sorry, looks like we sent you the wrong way.</div>
      <Link to={"/"}>Click here</Link>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    notFound: {
      margin: "0 auto",
      paddingTop: "20%",
      paddingLeft: "35%",
    },
  })
)

export default NotFound
