import { CircularProgress, makeStyles } from "@material-ui/core"
import * as Sentry from "@sentry/react"
// @ts-ignore
import { BraintreeDropIn } from "braintree-web-react"
import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { BRAINTREE_DO_NOT_HONOR } from "../../../constants"
import { orgState, paymentMethodState } from "../../../store"
import { PaymentMethodResponse } from "../../../types/types"
import api from "../../../utils/api"
import { apiErrorAlert } from "../../../utils/api-error-alert"
import Button from "../../atoms/Button"

const BrainTree = () => {
  const classes = useStyles()
  const [clientToken, setClientToken] = useState<string>("")
  const [btInstance, setBtInstance] = useState<any>(undefined)
  const [org, setOrg] = useRecoilState(orgState)
  const [paymentMethod, setPaymentMethod] = useRecoilState<
    PaymentMethodResponse | undefined
  >(paymentMethodState)
  const [processing, setProcessing] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)

  useEffect(() => {
    let subscribe = true
    async function scoped() {
      const brainTreeTokenResponse = await api.getBrainTreeToken()
      if (brainTreeTokenResponse.status !== 200) {
        apiErrorAlert("Unable to fetch client token.", brainTreeTokenResponse)
      }
      if (subscribe) {
        setClientToken(brainTreeTokenResponse.data.clientToken)
      }
    }
    scoped()
    return () => {
      subscribe = false
    }
  }, [])

  const setInstance = (newInstance: any) => {
    if (newInstance.isPaymentMethodRequestable()) {
      setDisableSubmit(false)
    }
    newInstance.on("paymentMethodRequestable", (event: any) => {
      console.log("paymentMethodRequestable", event)
      setDisableSubmit(false)
    })

    newInstance.on("noPaymentMethodRequestable", (event: any) => {
      console.log("noPaymentMethodRequestable", event)
      setDisableSubmit(true)
    })

    setBtInstance(newInstance)
  }

  const onSubmit = async () => {
    Sentry.captureMessage(
      `Requesting a payment method nonce from Braintree. Instance ${btInstance}`
    )
    setProcessing(true)
    const { nonce } = await btInstance
      .requestPaymentMethod()
      .catch((err: { message: string; name: string }) => {
        apiErrorAlert(err.message, err)
        setProcessing(false)
        return
      })

    Sentry.captureMessage(`finished btInstance.requestPaymentMethod ${nonce}`)
    if (!nonce) {
      setProcessing(false)
      throw new Error("[BrainTree] failed to get payment method nonce.")
    }
    const response = await api.paymentNonce(nonce)
    if (response.status !== 201) {
      if (response.data.error === BRAINTREE_DO_NOT_HONOR) {
        apiErrorAlert(response.data.message)
        window.location.reload()
        return
      }
      apiErrorAlert(
        "There was a problem on our end processing your credit card.",
        response
      )
      setProcessing(false)
      return
    }
    if (!org) {
      setProcessing(false)
      throw new Error("missing org.")
    }
    setOrg({ ...org, billingAccount: response.data.billingAccount })
    setPaymentMethod(response.data)
    setProcessing(false)
  }

  const defaultPaymentMethod = paymentMethod?.paymentMethods.find(
    pm => pm.default === true
  )
  const submitText = defaultPaymentMethod ? "Update Payment Method" : "Submit"
  return (
    <>
      <div className={classes.register}>
        {clientToken ? (
          <>
            <div className={classes.formWrapper}>
              <BraintreeDropIn
                options={{ authorization: clientToken }}
                onInstance={setInstance}
              />

              <div className={classes.submitWrapper}>
                <Button
                  className="primary"
                  txt={submitText}
                  onClick={onSubmit}
                  disabled={disableSubmit}
                />
              </div>
              <div className={processing ? classes.formOverlay : classes.hide}>
                <div className={classes.spinner}>
                  <CircularProgress />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles({
  spinner: {
    width: "100%",
    height: "100%",
    marginTop: "200px",
    textAlign: "center",
  },
  register: {
    margin: "16px 0",
  },
  formWrapper: {
    position: "relative",
    width: "100%",
  },
  formOverlay: {
    zIndex: 1000,
    background: "#F9F9FB",
    position: "absolute",
    height: 600,
    width: 400,
    top: 0,
    left: 0,
  },
  submitWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  hide: {
    display: "none",
  },
  cardEnteredMessage: {
    fontSize: "larger",
    textAlign: "center",
  },
})
export default BrainTree
