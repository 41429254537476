import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { SENTRY_PROJECT_TAG } from "./constants"
import reportWebVitals from "./reportWebVitals"
import "./styles/index.css"

Sentry.init({
  dsn:
    "https://dab04e162baf47d2a232fd47659ecbc1@o261101.ingest.sentry.io/5600905",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
Sentry.setTag("project_id", SENTRY_PROJECT_TAG)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
