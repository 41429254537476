import { createStyles, makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { OrgUsage } from "../../../types/types"
import { Card } from "../../../utils/theme"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const date = new Date()
const month = date.getMonth()
const year = date.getFullYear()

type CurrentBalanceProps = {
  orgUsage: OrgUsage
}

const Balance: FC<CurrentBalanceProps> = ({ orgUsage }) => {
  const styles = useStyles()
  const avUsage = orgUsage.audioUnitsUsed + orgUsage.videoUnitsUsed
  return (
    <div className={styles.card}>
      <div className={styles.upperData}>
        <div className={styles.title}>Current Balance</div>
        <div className={styles.nextStatement}>
          Next Statement: {monthNames[month + 1]} 1, {year}
        </div>
      </div>
      <div className={styles.avData}>
        <div className={styles.currentBalance}>
          {(orgUsage.avUnitsLimit - avUsage).toLocaleString()} Units
        </div>
        <div className={styles.billedAt}> Audio/Video units available</div>
      </div>
      <div className={styles.textData}>
        <div className={styles.currentBalance}>
          {(orgUsage.textUnitsLimit - orgUsage.textUnitsUsed).toLocaleString()}{" "}
          Units
        </div>
        <div className={styles.billedAt}> Text units available</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      fontSize: 32,
      fontStyle: "normal",
    },
    billedAt: {
      color: "##6A6068",
      paddingTop: 30,
      paddingLeft: 10,
    },
    nextStatement: {
      color: "#6A6068",
      paddingTop: 15,
      paddingLeft: 10,
      display: "none",
    },
    upperData: {
      display: "inline-flex",
    },
    avData: {
      display: "flex",
    },
    textData: {
      display: "flex",
      paddingTop: 25,
    },
    currentBalance: {
      fontSize: 48,
      color: "#3A5A80",
    },
    card: Card,
  })
)

export default Balance
