import { createStyles, makeStyles } from "@material-ui/styles"
import React, { FC } from "react"
import { AlertCircle } from "react-feather"
import styled from "styled-components"

type AlertBannerMode = "warning" | "error" | "info"

interface AlertBannerProps {
  children: React.ReactNode
  mode: AlertBannerMode
}

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: "100%";
  height: 48px;
  padding: 12px;
  border-radius: 2px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`

const AlertBanner: FC<AlertBannerProps> = ({ children, mode }) => {
  const classes = useStyles()
  return (
    <Banner className={classes[mode]}>
      <AlertCircle className={classes.icon} />
      <div className={classes.content}>{children}</div>
    </Banner>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    warning: { background: "#FFC658", color: "#2F232D" },
    error: { background: "#CC3333", color: "#FFFFFF" },
    info: { background: "#3A5A80", color: "#FFFFFF" },
    icon: {
      width: "24px",
      height: "24px",
    },
    content: {
      margin: "0px 8px",
    },
  })
)

export default AlertBanner
