import React from "react"
import logo from "../Logo/logo.png"
import "./logoStyles.css"

const Logo = () => {
  return (
    <div>
      <img className="logo" src={logo} alt="logo" />
    </div>
  )
}
export default Logo
