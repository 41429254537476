import React from "react"
import "./buttonStyles.css"

const Button = (props: any) => {
  return (
    <button {...props} className={props.className} onClick={props.onClick}>
      {props.txt}
    </button>
  )
}

export default Button
