import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import React from "react"
import { BUILD_ID, COMMIT_SHA } from "../../build_info.json"
import Header from "../organisms/Header/Header"
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#F9F9FB",
      color: "#3A5A80",
    },
    body: {
      fontSize: 14,
      fontWeight: "normal",
    },
  })
)(TableCell)

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {},
  })
)(TableRow)

const Build = () => {
  const styles = useStyles()

  return (
    <div className={styles.dash}>
      <Header />
      <div className={styles.table}>
        <h1>Build Info</h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Build Id</StyledTableCell>
                <StyledTableCell align="left">Commit SHA</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow key="buildId">
                <StyledTableCell component="th" scope="row">
                  {BUILD_ID}
                </StyledTableCell>
                <StyledTableCell align="left">{COMMIT_SHA}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    dash: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100vh",
      backgroundColor: "#F9F9FB",
      alignItems: "center",
      justifyContent: "center",
    },
    table: {
      width: "80%",
      marginTop: -450,
    },
  })
)

export default Build
