import {
  Checkbox,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import api from "../../../utils/api"
import { apiErrorAlert } from "../../../utils/api-error-alert"
import { colors } from "../../../utils/theme"
import Button from "../../atoms/Button"
import Error from "../../atoms/Error"
import Input from "../../atoms/Input"

const Register = () => {
  const history = useHistory()
  const classes = useStyles()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [orgName, setOrgName] = useState<string>("")
  const [checked, setChecked] = React.useState(false)
  const [errorMessages, seterrorMessages] = React.useState("")

  function invalidFormString(errorBool: boolean, formArray: any) {
    let allErrorsString = ""
    if (errorBool === true) {
      formArray.forEach((e: string | number) => {
        allErrorsString += e + "\n"
      })
    }
    return allErrorsString
  }

  function formErrors(errors: any) {
    seterrorMessages("")

    if (errors.message[0] === "email must be an email") {
      seterrorMessages(errors.message)
    }
    if (errors.message === "Invalid Form") {
      const emailErrors = errors.formErrors.email !== undefined
      const orgNameErrors = errors.formErrors.orgName !== undefined
      const passwordErrors = errors.formErrors.password !== undefined
      const errorText =
        invalidFormString(emailErrors, errors.formErrors.email) +
        invalidFormString(orgNameErrors, errors.formErrors.orgName) +
        invalidFormString(passwordErrors, errors.formErrors.password)
      seterrorMessages(errorText)
    }
  }

  const onSubmit = async () => {
    if (checked) {
      const registrationCall = await api.register(email, password, orgName)
      if (registrationCall.status === 400) {
        formErrors(registrationCall.data)
        return
      } else if (registrationCall.status !== 201) {
        apiErrorAlert("An unknown error has occured.", registrationCall)
      }
      history.push("/message")
    } else {
      seterrorMessages("You must agree to Terms of Service")
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <div className={classes.login}>
      <div className={classes.card}>
        <Typography
          variant="h4"
          component="h1"
          className={clsx(classes.marginBottom)}
        >
          Create Account
        </Typography>
        <Input
          variant="icon"
          type="text"
          placeholder="Organization Name"
          value={email}
          onChange={async (e: {
            target: { value: React.SetStateAction<string> }
          }) => setOrgName(e.target.value)}
        />
        <Input
          variant="icon"
          type="email"
          placeholder="email"
          value={email}
          onChange={async (e: {
            target: { value: React.SetStateAction<string> }
          }) => setEmail(e.target.value)}
        />
        <Input
          variant="icon"
          type="password"
          placeholder="password"
          value={password}
          onChange={async (e: {
            target: { value: React.SetStateAction<string> }
          }) => {
            setPassword(e.target.value)
          }}
        />
        <div className={classes.errorMessage}>
          <Error text={errorMessages}></Error>
        </div>
        <div className={classes.passwordHelpText}>
          Password must contain 8 characters, 1 letter, and 1 number or symbol
          <div>
            I agree to{" "}
            <Link to="/tos" target="_blank">
              {" "}
              Terms Of Service
            </Link>
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
        <Button
          className="primary"
          txt={"Create Account"}
          disabled={!checked}
          onClick={onSubmit}
        />
        <div className={classes.haveAnaccountQuestion}>
          Already have an account? <Link to="/"> Sign In</Link>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    login: {
      display: "flex",
      backgroundColor: colors.neutral.grey,
      alignItems: "center",
      justifyContent: "center",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    card: {
      display: "flex",
      flexDirection: "column",
      minWidth: 400,
      backgroundColor: colors.neutral.white,
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: colors.neutral.grey,
      padding: theme.spacing(4),
    },
    passwordHelpText: {
      maxWidth: 305,
      paddingBottom: 30,
    },
    haveAnaccountQuestion: {
      maxWidth: 305,
      paddingBottom: 30,
      paddingTop: 30,
    },
    errorMessage: {
      paddingBottom: 25,
    },
  })
)

export default Register
