import {
  Backdrop,
  CircularProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import React, { FC, useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { BillingErrorStates, SUPPORT_EMAIL } from "../../constants"
import { orgState } from "../../store"
import { OrgUsage, UsageDetail, UsageResponse } from "../../types/types"
import api from "../../utils/api"
import { apiErrorAlert } from "../../utils/api-error-alert"
import { colors, DefaultWrapper } from "../../utils/theme"
import AlertBanner from "../atoms/AlertBanner/AlertBanner"
import AnalysisTable from "../molecules/AnalysisTable/AnalysisTable"
import Balance from "../molecules/Balance/Balance"
import Quota from "../molecules/Quota/Quota"
import Navigation from "../organisms/Navigation/NavigationSide"

const USAGE_WARNING_THRESHOLD = 80
const USAGE_ERROR_THRESHOLD = 95

type UsageAlertMode = "warning" | "error" | null

interface UsageWarningResult {
  mode: UsageAlertMode
  usage: number
}

const Dashboard: FC = ({ children }) => {
  console.log("[Dashboard]")
  const classes = useStyles()
  const [apiData, setApiData] = useState<UsageResponse>()

  const [userTableData, setUserTableData] = useState<UsageDetail[]>([])
  const [orgUsage, setOrgUsage] = useState<OrgUsage | undefined>(undefined)
  const [org, setOrg] = useRecoilState(orgState)
  const [currentBalanceState, setCurrentBalanceState] = useState<
    undefined | number
  >(undefined)
  const [
    showAccountNotActivatedBanner,
    setShowAccountNotActivatedBanner,
  ] = useState(false)
  const [spinnerShow, setSpinnerShow] = useState(true)
  const [dashShow, setDashShow] = useState(false)
  const [quotaWarning, setQuotaWarning] = useState<UsageWarningResult>({
    mode: null,
    usage: 0,
  })

  useEffect(() => {
    async function getUsageData() {
      let subscribe = true
      const usageResponse = await api.usageData()

      if (subscribe) {
        if (usageResponse.status !== 200) {
          apiErrorAlert("Unable to load usage data.", usageResponse)
        }
        setApiData(usageResponse.data)
      }
      subscribe = false
    }
    getUsageData()
  }, [setApiData])

  function currentBalance(orgUsage: OrgUsage): number {
    const avUsed = orgUsage.videoUnitsUsed + orgUsage.audioUnitsUsed
    const avLimit = orgUsage.avUnitsLimit - avUsed
    return avLimit
  }

  const getUsageWarning = (orgUsage: OrgUsage): UsageWarningResult => {
    if (orgUsage.avUnitsLimit === 0 || orgUsage.textUnitsLimit === 0) {
      return { mode: null, usage: 0 }
    }
    let mode: UsageAlertMode = null
    const avUnitsUsed = orgUsage.audioUnitsUsed + orgUsage.videoUnitsUsed
    const usage =
      Math.max(
        avUnitsUsed / orgUsage.avUnitsLimit,
        orgUsage.textUnitsUsed / orgUsage.textUnitsLimit
      ) * 100

    if (usage >= USAGE_WARNING_THRESHOLD && usage < USAGE_ERROR_THRESHOLD) {
      mode = "warning"
    } else if (usage >= USAGE_ERROR_THRESHOLD) {
      mode = "error"
    }

    return { mode, usage }
  }

  useEffect(() => {
    async function getUserData() {
      console.log("[Dashboard] useEffect")
      if (apiData !== undefined) {
        setShowAccountNotActivatedBanner(!org?.billingAccount)
        setOrgUsage(apiData.orgUsage)
        setUserTableData(apiData.details)

        if (apiData.orgUsage.active && apiData.orgUsage.canProcess) {
          setCurrentBalanceState(currentBalance(apiData.orgUsage))
        }
        // when API returns values show the component
        setDashShow(true)
        setSpinnerShow(false)
        setQuotaWarning(getUsageWarning(apiData.orgUsage))
      }
    }
    getUserData()
  }, [apiData, setOrgUsage, org])

  const showBillingErrorBanner = BillingErrorStates.includes(
    org?.billingAccount?.state ?? ""
  )

  return (
    <>
      <Navigation />
      <div className={classes.wrapper}>
        <Backdrop
          open={spinnerShow}
          className={spinnerShow === true ? classes.backdrop : classes.hide}
        >
          <CircularProgress />
        </Backdrop>
        <div
          className={dashShow === true ? classes.dataComponents : classes.hide}
        >
          {quotaWarning.mode === "warning" ? (
            <AlertBanner mode={quotaWarning.mode}>
              You have have used {quotaWarning.usage.toFixed(0)}% of your
              monthly quota.
            </AlertBanner>
          ) : null}
          {quotaWarning.mode === "error" ? (
            <AlertBanner mode={quotaWarning.mode}>
              You have used {quotaWarning.usage.toFixed(0)}% of your monthly
              quota. Please{" "}
              <a href={`mailto: ${SUPPORT_EMAIL}`}>contact support</a> to
              increase.
            </AlertBanner>
          ) : null}
          {showBillingErrorBanner ? (
            <AlertBanner mode="error">
              Your method of payment failed. Please contact support to resolve
              and continue using ContextualPlatform.
            </AlertBanner>
          ) : null}
          {showAccountNotActivatedBanner ? (
            <AlertBanner mode="warning">
              Your account is not activated. Please click on{" "}
              <a href="/payment">Manage Payment</a> and enter your credit card
              information to activate you account.
            </AlertBanner>
          ) : null}

          <div className={classes.label}>Usage Details</div>
          <div className={classes.usageStats}>
            {orgUsage ? (
              <>
                <Quota orgUsage={orgUsage} />
                <Balance orgUsage={orgUsage} />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.analysisTable}>
            <AnalysisTable data={userTableData} />
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    wrapper: {
      ...DefaultWrapper,
      minWidth: 900,
    },
    backdrop: {
      zIndex: 100,
    },
    dataComponents: {},
    spinner: {},
    card: {
      display: "flex",
      marginTop: 20,
      // minWidth: 500,
      backgroundColor: "#F9F9FB",
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: colors.neutral.grey,
      padding: theme.spacing(4),
    },

    usageStats: {
      width: "100%",
      display: "flex",
      gap: 40,
      "& div": {
        flexGrow: 1,
      },
    },
    label: {
      alignSelf: "baseline",
      fontSize: 40,
      fontWeight: 500,
    },
    analysisTable: {
      paddingTop: 25,
      width: "100%",
    },
    hide: {
      display: "none",
    },
  })
)

export default Dashboard
