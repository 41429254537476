import React from "react"
import "./inputStyles.css"

const Input = (props: any) => {
  return (
    <input
      className={props.variant}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  )
}

export default Input
