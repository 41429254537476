import { CssBaseline, ThemeProvider } from "@material-ui/core"
import React, { FC, Suspense } from "react"
import { CookiesProvider } from "react-cookie"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { RecoilRoot, useRecoilValue } from "recoil"
import NotFound from "./components/atoms/NotFound/NotFound"
import AnonContainer from "./components/organisms/AnonContainer"
import Build from "./components/screens/Build"
import Dashboard from "./components/screens/Dashboard"
import Examples from "./components/screens/Examples"
import Keys from "./components/screens/Keys"
import LoginScreen from "./components/screens/LoginScreen"
import Message from "./components/screens/Message"
import Payment from "./components/screens/Payment"
import Register from "./components/screens/Register"
import TOS from "./components/screens/TOS"
import { sessionState } from "./store"
import { theme } from "./utils/theme"

const Root: FC = () => {
  const session = useRecoilValue(sessionState)

  return (
    <BrowserRouter>
      {/* PUBLIC ROUTES */}
      {!session && (
        <AnonContainer>
          <Switch>
            <Route exact path={"/"}>
              <LoginScreen />
            </Route>
            <Route exact path={"/login"}>
              <LoginScreen />
            </Route>
            <Route exact path={"/register"}>
              <Register />
            </Route>
            <Route exact path={"/message"}>
              <Message />
            </Route>
            <Route exact path={"/tos"}>
              <TOS />
            </Route>
            <Route exact path={"/build"}>
              <Build />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </AnonContainer>
      )}

      {/* PRIVATE ROUTES */}
      {session && (
        <Switch>
          <Route path={"/dashboard"}>
            <Dashboard />
          </Route>
          <Route path={"/examples"}>
            <Examples />
          </Route>
          <Route path={"/keys"}>
            <Keys />
          </Route>
          <Route path={"/payment"}>
            <Payment />
          </Route>
          <Route path={"/tos"}>
            <TOS />
          </Route>
          <Route path={"/build"}>
            <Build />
          </Route>
          <Route path={"/"}>
            <Dashboard />
          </Route>
          <Route component={NotFound} />
        </Switch>
      )}
    </BrowserRouter>
  )
}

function App() {
  return (
    <Suspense fallback={""}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <RecoilRoot>
            <CssBaseline />
            <Root />
          </RecoilRoot>
        </CookiesProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
