import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { DefaultWrapper } from "../../utils/theme"
import Navigation from "../organisms/Navigation/NavigationSide"

const Examples = () => {
  const styles = useStyles()
  return (
    <div className={styles.wrapper}>
      <Navigation />
      <div className={styles.doc}>
        <div>
          <h1>Overview</h1>
          The following examples assume you have set an environment variable
          named <b>DBMO_API_KEY</b> containing your API key.{" "}
          <a href="/keys" target="_blank">
            You can obtain your API Key here
          </a>
          .
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
            export DBMO_API_KEY=<YOUR_API_KEY>
            `}</code>
          </pre>
          <p>
            Note: currently, the only supported media formats are <i>.mp4</i>
            for videos and <i>.txt</i> for text files.
          </p>
          <p>
            The following API examples use the <b>curl</b> command, and their
            output is in JSON format. To pretty print the JSON output you can
            pipe it through <b>json_pp</b> by appending <b>| json_pp</b>
            to the end of the curl command.
          </p>
        </div>
        <div>
          <h1>Upload and Process Media</h1>
          <h2>Example: Uploading a local file</h2>
          If your local media file is located at
          <i>/local/path/to/media/my-video.mp4</i>
          you can upload and process it with the following curl command
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
            curl -X POST \
            -H "Authorization: Bearer $DBMO_API_KEY" \
            -H "Content-Type: multipart/form-data" \
            -F "file=@/local/path/to/media/my-video.mp4" \
            -F "publisher=Acme Media" \
            -F "title=Some Title" \
            -F "show=Some Show" \
            -F "filename=some-show.mp4" \
            -F "tags=Sports|Football|My Custom Tag" \
            https://api.dbmoai.com/upload/
          `}</code>
          </pre>
          After your media finishes uploading, the API will return a
          <b> mediaId </b> and an <b>analysisId</b>. The <b>mediaId</b> is the
          unique id for your asset in the Contextual Platform system. You will
          use the mediaID to reference this media from now on. The
          <b> analysisId</b> is the id of this particular analysis run. A media
          asset can have multiple <b>analysisId</b>'s. For instance, if you
          re-run the media asset, a new analysisId will be generated. All fields
          on the upload endpoint are optional and are for your workflow's
          identification of the media assets. Apply Tags to create pools of
          media assets that have a special meaning in the system.
          <h2>Example Re-running Analysis on Existing Media</h2>
          If you have already uploaded media to Contextual Platform you can
          re-process it using the <b>mediaId</b>. This will create a new
          <b> analysisId</b>. For example to re-run an asset with <b>mediaId</b>
          {EXAMPLE_MEDIA_ID}:
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
            curl -X POST \
            -H "Authorization: Bearer $DBMO_API_KEY" \
            -H "Content-Type: application/json" \
            https://api.dbmoai.com/analysis/${EXAMPLE_MEDIA_ID}}
          `}</code>
          </pre>
          <h3>
            Example Response for both uploading a new asset and re-running an
            existing asset
          </h3>
          <pre className={`${styles.codeBox} ${styles.codeBoxJson}`}>
            <code>{JSON.stringify(UPLOAD_RESPONSE_EXAMPLE, null, 2)}</code>
          </pre>
          You should keep track of the <b>mediaId</b> and <b>analysisId</b> for
          future reference. The <b>estimatedUsage</b> object provides
          information on how many units will be required to process the asset
          and how many units you have available. If processing the asset puts
          you over your usage limit the <b>canProcess</b> key will be false and
          analysis will not process for the media. The analysis process is
          asynchronous and can take several minutes to an hour or more,
          depending on the size of your media. You can use the `analysisId` to
          check the status. For example if the returned <b>analysisId</b> is{" "}
          {EXAMPLE_ANALYSIS_ID}:<h2>Check Analysis Status</h2>
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
            curl -v -H "Authorization: Bearer $DBMO_API_KEY" https://api.dbmoai.com/analysis/${EXAMPLE_ANALYSIS_ID}/results
            `}</code>
          </pre>
          Once complete, the <b>status</b> will be marked as complete. If there
          are no errors, these <b>results</b> array will contain a list of URI
          to the results. See the next section on how to check the results.
          <h3>Analysis Status Response</h3>
          <pre className={`${styles.codeBox} ${styles.codeBoxJson}`}>
            <code>
              {JSON.stringify(ANALYSIS_STATUS_RESPONSE_EXAMPLE, null, 2)}
            </code>
          </pre>
          <h1>Checking Analysis Results</h1>
          Once the analysis is complete, you can check individual results.
          <h2>Topics Example</h2>
          To check the topics generated for a media asset
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
              curl -v -H "Authorization: Bearer $DBMO_API_KEY" https://api.dbmoai.com/analysis/${EXAMPLE_ANALYSIS_ID}/results/topics    
            `}</code>
          </pre>
          <h3>Topic Response Example</h3>
          <pre className={`${styles.codeBox} ${styles.codeBoxJson}`}>
            <code>
              {JSON.stringify(
                ANALYSIS_RESULTS_TOPICS_RESPONSE_EXAMPLE,
                null,
                2
              )}
            </code>
          </pre>
          <h2>Themes Example</h2>
          To check the themes generated for a media asset
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
              curl -v -H "Authorization: Bearer $DBMO_API_KEY" https://api.dbmoai.com/analysis/${EXAMPLE_ANALYSIS_ID}/results/themes     
            `}</code>
          </pre>
          <h3>Themes Response Example</h3>
          <pre className={`${styles.codeBox} ${styles.codeBoxJson}`}>
            <code>
              {JSON.stringify(
                ANALYSIS_RESULTS_THEMES_RESPONSE_EXAMPLE,
                null,
                2
              )}
            </code>
          </pre>
          <h2>Transcript Example</h2>
          To check the transcript generated for a media asset
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
              curl -v -H "Authorization: Bearer $DBMO_API_KEY" https://api.dbmoai.com/analysis/${EXAMPLE_ANALYSIS_ID}/results/transcript    
            `}</code>
          </pre>
          <h3>Transcript Response Example</h3>
          <pre className={`${styles.codeBox} ${styles.codeBoxJson}`}>
            <code>
              {JSON.stringify(
                ANALYSIS_RESULTS_TRANSCRIPT_RESPONSE_EXAMPLE,
                null,
                2
              )}
            </code>
          </pre>
          <h2>Phrases Example</h2>
          To check the phrases generated for a media asset
          <pre className={styles.codeBox}>
            <code className={styles.codeExample}>{`
              curl -v -H "Authorization: Bearer $DBMO_API_KEY" https://api.dbmoai.com/analysis/${EXAMPLE_ANALYSIS_ID}/results/phrases   
            `}</code>
          </pre>
          <h3>Phrases Response Example</h3>
          <pre className={`${styles.codeBox} ${styles.codeBoxJson}`}>
            <code>
              {JSON.stringify(
                ANALYSIS_RESULTS_PHRASES_RESPONSE_EXAMPLE,
                null,
                2
              )}
            </code>
          </pre>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      ...DefaultWrapper,
      maxWidth: 900,
    },
    doc: {
      maxWidth: "80%",
    },
    codeBox: {
      background: "rgb(40, 44, 52)",
      color: "rgb(255, 255, 255)",
      borderRadius: "10px",
      overflow: "auto",
      tabSize: "1.5em",
      maxWidth: "80%",
      whiteSpace: "nowrap",
      paddingLeft: 15,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
    },
    codeBoxJson: {
      whiteSpace: "break-spaces",
    },
    codeExample: {
      paddingLeft: 10,
    },
  })
)

export default Examples

const EXAMPLE_MEDIA_ID = "f847f3e4-f71c-48eb-8199-2bdaf3a033df"
const EXAMPLE_ANALYSIS_ID = "a025e0c7-685c-4ebc-89f5-a1693dd640d2"

const UPLOAD_RESPONSE_EXAMPLE = {
  analysisId: EXAMPLE_ANALYSIS_ID,
  mediaId: EXAMPLE_MEDIA_ID,
  estimatedUsage: { units: 4, type: "text" },
  orgUsage: {
    active: true,
    audioUnitsUsed: 0,
    textUnitsUsed: 0,
    videoUnitsUsed: 0,
    canProcess: true,
    orgId: 3411,
    avUnitsLimit: 5,
    textUnitsLimit: 50,
  },
  isTrial: false,
}

const ANALYSIS_STATUS_RESPONSE_EXAMPLE = {
  analysisId: EXAMPLE_ANALYSIS_ID,
  is_trial: false,
  status: "complete",
  usageUnits: 0,
  config: {
    run_id: "",
    cache_mode: "cache_enabled",
    media_uuid: EXAMPLE_MEDIA_ID,
    topic_sheet_filename: "topics_2021_08_31T20_14_51.pickle",
  },
  hyperparameters: {},
  error: false,
  startedAt: "2021-09-10T18:23:34.198101+00:00",
  completedAt: "2021-09-10T18:23:49.393655+00:00",
  createdAt: "2021-09-10T18:23:34.134215+00:00",
  __typename: "job",
  mediaId: EXAMPLE_MEDIA_ID,
  results: {
    resultLinks: [
      `/analysis/${EXAMPLE_ANALYSIS_ID}/results/phrases`,
      `/analysis/${EXAMPLE_ANALYSIS_ID}/results/themes`,
      `/analysis/${EXAMPLE_ANALYSIS_ID}/results/topics`,
      `/analysis/${EXAMPLE_ANALYSIS_ID}/results/transcript`,
    ],
  },
}

const ANALYSIS_RESULTS_TOPICS_RESPONSE_EXAMPLE = {
  analysisId: EXAMPLE_ANALYSIS_ID,
  mediaId: EXAMPLE_MEDIA_ID,
  topics: [
    {
      topic: "Sloane Stephens",
      confidence: 0.999981542106704,
      signal: 57,
      tokenSignals: [
        {
          token: "sloane stephens",
          tokenType: "named_entity",
          defaultWeight: 1.5,
          confidence: 0.99451370110055,
          signal: 28.5,
          weightLabel: "Auto Added",
          tfidfScore: null,
          tokenFrequency: 19,
          replaceWith: null,
          mentionList: [
            {
              mention_word_count: 1,
              mention_word_index: 94,
              mention_start_char: 536,
              end_time_milliseconds: 88000,
              start_time_milliseconds: 87400,
              mention_end_char: 542,
              mention_text: "Sloane",
              label_weight: 1.5,
            },
          ],
        },
      ],
      topicCategory: {
        name: "Uncategorized",
        id: "0d015d96f63a8c12d96b8399482b593f",
      },
      id: "3e52802161fcb6c6b966729c176b410c",
    },
  ],
}

const ANALYSIS_RESULTS_THEMES_RESPONSE_EXAMPLE = {
  mediaId: EXAMPLE_MEDIA_ID,
  analysisId: EXAMPLE_ANALYSIS_ID,
  themes: [
    {
      name: "Historical",
      signal: 30.0342239134661,
      themeCategory: {
        name: "Romance",
        id: "081fc8e8bd39e1dc72ca3f5a5fdf6697",
      },
      confidence: 0.995957514128453,
      id: "1df940294e43cce1f43fe5cd4e103b94",
    },
  ],
}

const ANALYSIS_RESULTS_TRANSCRIPT_RESPONSE_EXAMPLE = {
  mediaId: EXAMPLE_MEDIA_ID,
  analysisId: EXAMPLE_ANALYSIS_ID,
  transcript:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
}

const ANALYSIS_RESULTS_PHRASES_RESPONSE_EXAMPLE = {
  mediaId: EXAMPLE_MEDIA_ID,
  analysisId: EXAMPLE_ANALYSIS_ID,
  phrases: [
    {
      topics: [],
      id: "5962",
      time: [1400, 1600],
      phrase: "great",
    },
    {
      id: "5963",
      topics: [
        {
          id: "19bf99f6abb93fbe21c3ca9cebe96304",
          name: "Boxing",
        },
        {
          id: "57fb27deb6f0e9c3feac9afe8bddb9f9",
          name: "Contest",
        },
        {
          id: "3b962574887f124e78bfd9b4685f3b39",
          name: "Cooking",
        },
      ],
      time: [1600, 2100],
      phrase: "champion",
    },
  ],
}
