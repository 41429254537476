import { createStyles, makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { colors } from "../../utils/theme"
import Header from "./Header/Header"

const AnonContainer: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.anonContainer}>
      <Header></Header>
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    anonContainer: {
      background: colors.blueBackground,
    },
  })
)

export default AnonContainer
