import { createStyles, makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import styled from "styled-components"

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  /* Interface/Neutral/White */

  background: #ffffff;
  /* Interface / Neutral / 5 */

  border: 1px solid #d4d3d3;
  box-sizing: border-box;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
`

export const Card: FC = ({ children }) => {
  const classes = useStyles()
  return <StyledCard>{children}</StyledCard>
}

const StyledCardTitle = styled.div`
  width: 100%;
  display: flex;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #2f232d;

  flex-direction: row;
  align-items: center;
`

export const CardTitle: FC = ({ children }) => {
  const classes = useStyles()
  return <StyledCardTitle>{children}</StyledCardTitle>
}

const StyledCardContent = styled.div`
  width: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

export const CardContent: FC = ({ children }) => {
  const classes = useStyles()
  return <StyledCardContent>{children}</StyledCardContent>
}

const StyledCardChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 8px;
  height: 18px;

  background: rgba(209, 240, 226, 0.5);
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
`

export const CardChip: FC = ({ children }) => {
  const classes = useStyles()
  return <StyledCardChip>{children}</StyledCardChip>
}

const useStyles = makeStyles(theme => createStyles({}))
