import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import "./errorStyles.css"

type MessageProps = {
  text: string
}

const Message = ({ text }: MessageProps) => {
  const classes = useStyles()
  return (
    <div className={classes.error}>
      <div>{text}</div>
    </div>
  )
}
const useStyles = makeStyles(theme =>
  createStyles({
    error: {
      whiteSpace: "pre-line",
      color: "red",
    },
  })
)

export default Message
