import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { orgState } from "../../store"
import api from "../../utils/api"
import { apiErrorAlert } from "../../utils/api-error-alert"
import { DefaultWrapper } from "../../utils/theme"
import AlertBanner from "../atoms/AlertBanner/AlertBanner"
import Button from "../atoms/Button"
import Navigation from "../organisms/Navigation/NavigationSide"

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#F9F9FB",
      color: "#3A5A80",
    },
    body: {
      fontSize: 14,
      fontWeight: "normal",
    },
  })
)(TableCell)

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {},
  })
)(TableRow)

const Keys = () => {
  const styles = useStyles()
  const [userKeyData, setKeyeData] = useState("Key not retrieved")
  const org = useRecoilValue(orgState)
  const [
    showAccountNotActivatedBanner,
    setShowAccountNotActivatedBanner,
  ] = useState(false)

  useEffect(() => {
    async function getUserData() {
      setShowAccountNotActivatedBanner(!org?.billingAccount)

      const authTokenResponse = await api.devAuthToken()
      if (authTokenResponse.status !== 200) {
        apiErrorAlert("Unable to retrieve API key.", authTokenResponse)
      }
      setKeyeData(authTokenResponse.data.token)
    }
    getUserData()
  }, [org])

  const [keyReveal, setKeyReveal] = useState(false)
  function showKey() {
    setKeyReveal(true)
  }

  return (
    <div className={styles.wrapper}>
      <Navigation />
      <div>
        {showAccountNotActivatedBanner ? (
          <AlertBanner mode="warning">
            Your account is not activated. Please click on{" "}
            <a href="/payment">Manage Payment</a> and enter your credit card
            information to activate you account.
          </AlertBanner>
        ) : null}
        <h1>API Keys</h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Organization</StyledTableCell>
                <StyledTableCell align="left">API Key</StyledTableCell>
              </TableRow>
            </TableHead>
            {org ? (
              <TableBody>
                <StyledTableRow key="Org Name">
                  <StyledTableCell component="th" scope="row">
                    {org.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div>
                      <div style={{ display: keyReveal ? "none" : "block" }}>
                        <Button
                          class="secondary"
                          txt="Reveal Hidden Key"
                          onClick={async () => {
                            showKey()
                          }}
                        />
                      </div>
                      <div style={{ display: keyReveal ? "block" : "none" }}>
                        {userKeyData}
                      </div>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ) : (
              <></>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: DefaultWrapper,
  })
)

export default Keys
