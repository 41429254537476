import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { colors } from "../../utils/theme"
import Message from "../atoms/Message"
import Header from "../organisms/Header/Header"

const MessagePage = () => {
  const styles = useStyles()
  return (
    <div className={styles.messagePage}>
      <Header />
      <Message
        title="Email Sent"
        text="Check your email more information on validating your account."
        link="/"
        linkText="Return to Login"
      ></Message>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    messagePage: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100vh",
      backgroundColor: colors.blueBackground,
      alignItems: "center",
      justifyContent: "center",
    },
  })
)

export default MessagePage
