import { createMuiTheme } from "@material-ui/core"

const primaryFont = "Work Sans"

export const colors = {
  neutral: {
    white: "#FFFFFF",
    grey: "#C8CDDB",
    darkish: "#1E263C",
    dark: "rgba(30,38,60,0.8)",
  },
  blueBackground: "#ECF4FE",
}

export const theme = createMuiTheme({
  spacing: 8,
  typography: {
    fontFamily: [primaryFont, "san-serif"].join(","),
    fontSize: 16,
    h1: {
      fontFamily: primaryFont,
      size: 34,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    h2: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 30,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    h3: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 24,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    h4: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 20,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    h5: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 18,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    h6: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 16,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    subtitle1: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 20,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    subtitle2: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 16,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    body1: {
      fontFamily: primaryFont,
      fontWeight: "normal",
      fontStyle: "normal",
      size: 16,
      letterSpacing: 0.3,
      color: "#6A6068",
    },
    body2: {
      fontFamily: primaryFont,
      fontWeight: "normal",
      fontStyle: "normal",
      size: 14,
      letterSpacing: 0.3,
      color: colors.neutral.dark,
    },
    button: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 20,
      letterSpacing: 0.3,
      color: colors.neutral.darkish,
    },
    caption: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 14,
      letterSpacing: 0.3,
      color: colors.neutral.dark,
    },
    overline: {
      fontFamily: primaryFont,
      fontWeight: 700,
      size: 12,
      letterSpacing: 0.5,
      color: colors.neutral.dark,
    },
  },
})

export const Card = {
  marginTop: 20,
  minWidth: 470,
  backgroundColor: "#F9F9FB",
  borderRadius: 4,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: colors.neutral.grey,
  padding: theme.spacing(4),
}

export const Icon = {
  width: "24px",
  height: "24px",
}

export const DefaultWrapper = {
  maxWidth: 700,
  minWidth: 600,
  backgroundColor: "#F9F9FB",
  marginLeft: 280,
  marginRight: 40,
  marginTop: 86,
}
