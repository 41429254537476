import { createStyles, makeStyles, Typography } from "@material-ui/core"
import * as Sentry from "@sentry/react"
import clsx from "clsx"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useSessionStore } from "../../../hooks/useSessionStore"
import { colors } from "../../../utils/theme"
import Button from "../../atoms/Button"
import Error from "../../atoms/Error"
import Input from "../../atoms/Input"

const LogIn = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const { login, verifyEmail } = useSessionStore()
  const [error, setError] = useState<boolean>(false)
  const [tokenExpiredError, setTokenExpiredError] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    async function attemptTokenLogin(token: string) {
      console.log(`attempting to login with token ${token}`)
      setTokenExpiredError(false)

      const response = await verifyEmail(token)
      if (response.status === 201) {
        if (!response.data.user.org.billingAccount) {
          history.push("/payment")
        } else {
          history.push("/dashboard")
        }
      } else {
        setTokenExpiredError(true)
      }
    }
    const values = queryString.parse(search)
    const token = values.token as string | undefined
    if (token) {
      attemptTokenLogin(token)
    }
  }, [])

  // inputs
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const onLogin = async (email: string, password: string) => {
    const response = await login(email, password)

    if (response.status >= 300) {
      setError(true)
      return
    }
    setError(false)
    Sentry.setUser({
      id: `${response.data.user.id}`,
      email: response.data.user.email,
    })
    Sentry.captureMessage(`${response.data.user.email} logged in.`)
    if (!response.data.user.org.billingAccount) {
      history.push("/payment")
    } else {
      history.push("/dashboard")
    }
  }

  return (
    <div>
      <div className={classes.card}>
        <Typography
          variant="h4"
          component="h1"
          className={clsx(classes.marginBottom)}
        >
          Log In
        </Typography>
        <Input
          variant="icon"
          type="email"
          placeholder="email"
          value={email}
          onChange={async (e: {
            target: { value: React.SetStateAction<string> }
          }) => setEmail(e.target.value)}
        />
        <Input
          variant="icon"
          type="password"
          placeholder="password"
          value={password}
          onChange={async (e: {
            target: { value: React.SetStateAction<string> }
          }) => {
            setPassword(e.target.value)
          }}
        />
        <div>
          {error === true ? (
            <Error text="The email or password you have entered is incorrect. Please try again."></Error>
          ) : (
            ""
          )}
        </div>
        <div>
          {tokenExpiredError === true ? (
            <Error text="Your verification token has expired. Please try registering again."></Error>
          ) : (
            ""
          )}
        </div>
        <Button
          className="primary"
          txt={"Login"}
          onClick={async () => {
            await onLogin(email, password)
          }}
        />
        <div className={classes.haveAnaccountQuestion}>
          Don't have an account? <Link to="/register"> Sign Up</Link>
        </div>
      </div>
    </div>
  )
}

export default LogIn

const useStyles = makeStyles(theme =>
  createStyles({
    login: {
      display: "flex",
      backgroundColor: colors.neutral.grey,
      alignItems: "center",
      justifyContent: "center",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    card: {
      display: "flex",
      flexDirection: "column",
      minWidth: 400,
      maxWidth: 400,
      backgroundColor: colors.neutral.white,
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: colors.neutral.grey,
      padding: theme.spacing(4),
    },
    haveAnaccountQuestion: {
      maxWidth: 305,
      paddingBottom: 30,
      paddingTop: 30,
    },
  })
)
