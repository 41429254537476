import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { colors } from "../../utils/theme"
import LogIn from "../molecules/Login/LogIn"
import Header from "../organisms/Header/Header"

const LoginScreen = () => {
  const styles = useStyles()
  return (
    <div className={styles.login}>
      <Header />
      <LogIn />
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    login: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100vh",
      backgroundColor: colors.blueBackground,
      alignItems: "center",
      justifyContent: "center",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
  })
)

export default LoginScreen
