import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios"
import Cookies from "js-cookie"
import {
  baseUrl,
  ERROR_SUPPORT_TEXT,
  INVALID_JWT_MESSAGE_CODE,
  JWT_ACCESS_TOKEN_EXPIRED_ERROR_MESSAGE,
  JWT_REFRESH_TOKEN_EXPIRED_ERROR_MESSAGE,
} from "../constants"
import {
  AccountResponse,
  AuthTokenResponse,
  LoginData,
  LoginResponse,
  PaymentMethodResponse,
  RegisterResponse,
  STORAGE,
  UsageResponse,
} from "../types/types"

export function getCookieObjectOrNone<T>(name: string): T | null {
  const value = Cookies.get(name)
  let object = null
  if (value) {
    try {
      object = JSON.parse(value) as T
    } catch (e) {
      console.log(`Could not parse cookie '${name}': ${value}`)
    }
  }
  return object
}

export const shouldLogout = (
  response: AxiosResponse
): { doLogout: boolean; message: string } => {
  const messages = [
    JWT_ACCESS_TOKEN_EXPIRED_ERROR_MESSAGE,
    JWT_REFRESH_TOKEN_EXPIRED_ERROR_MESSAGE,
    INVALID_JWT_MESSAGE_CODE,
  ]

  const doLogout = !!messages.find(m => {
    return response.data.message.includes(m)
  })
  return { doLogout, message: response.data.message }
}

// Add a response interceptor
axios.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    console.log(
      `axios.interceptors.response: (${response.status}) ${response.config.url}`
    )
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response) {
      const response = error.response
      const { doLogout, message } = shouldLogout(response)
      if (doLogout) {
        console.warn(`[axios.interceptor] Redirecting to login. ${message}`)
        window.alert(
          "Your session has expired. Please login again to continue."
        )
        sessionStorage.clear()
        Cookies.remove(STORAGE.LOGIN_DATA)
        window.location.href = "/login"
      }

      console.log(
        `axios.interceptors.response: (${response.status}) ${response.config.url}`
      )
      if (error.status >= 500) {
        console.log("axios.interceptors.response resolving")
        window.alert(`Unknown server error. ${ERROR_SUPPORT_TEXT}`)
      } else {
        console.log(
          "axios.interceptors.response. Have error.response rejecting promise."
        )
        return Promise.reject(error.response)
      }
    } else {
      console.log("axios.interceptors.response. error has no response")
      window.alert(`Unable to connect to the server. ${ERROR_SUPPORT_TEXT}`)
      throw new Error(
        `Unable to connect to the server. Error ${JSON.stringify(error)}`
      )
    }
  }
)

export class Api {
  private getLoginData(): LoginData {
    const loginData = getCookieObjectOrNone<LoginData>(STORAGE.LOGIN_DATA)
    if (!loginData) {
      throw new Error("[Api.getLoginData] loginData not found on Cookie")
    }
    return loginData
  }
  genericApiCall = async <T extends unknown>(
    url: string,
    method: Method,
    token: string,
    data?: {}
  ): Promise<AxiosResponse<T>> => {
    const headers: any = {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    }
    if (token) {
      headers["Authorization"] = `Bearer ${token}`
    }
    const config: AxiosRequestConfig = {
      method: method,
      url: url,
      headers,
      data: data,
    }

    const response = await axios(config).catch((response: AxiosResponse) => {
      return response
    })
    return response
  }

  authorizedApiCall = async <T extends unknown>(
    url: string,
    method: Method,
    data: any
  ): Promise<AxiosResponse<T>> => {
    const loginData = this.getLoginData()
    return await this.genericApiCall<T>(
      url,
      method,
      loginData.accessToken,
      data
    )
  }

  login = async (
    email: string,
    password: string
  ): Promise<AxiosResponse<LoginResponse>> => {
    const url = `${baseUrl}/account/login`
    const method = "post"
    const data = {
      email: email,
      password: password,
    }

    const response = await this.genericApiCall<LoginResponse>(
      url,
      method,
      "",
      data
    )
    return response
  }

  verifyEmail = async (token: string) => {
    const url = `${baseUrl}/account/verify-email/${token}`
    const method = "post"
    const response = await this.genericApiCall<LoginResponse>(url, method, "")
    return response
  }

  getAccount = async (): Promise<AxiosResponse<AccountResponse>> => {
    const apiReturn = await this.authorizedApiCall<AccountResponse>(
      `${baseUrl}/account`,
      "get",
      ""
    )
    return apiReturn
  }

  getBrainTreeToken = async (): Promise<
    AxiosResponse<{ clientToken: string }>
  > => {
    const apiReturn = await this.authorizedApiCall<{ clientToken: string }>(
      `${baseUrl}/billing/client-token`,
      "get",
      ""
    )
    return apiReturn
  }

  getPaymentMethod = async (): Promise<
    AxiosResponse<PaymentMethodResponse>
  > => {
    const apiReturn = await this.authorizedApiCall<PaymentMethodResponse>(
      `${baseUrl}/billing/payment-method`,
      "get",
      ""
    )
    return apiReturn
  }

  paymentNonce = async (
    nonce: string
  ): Promise<AxiosResponse<PaymentMethodResponse>> => {
    const apiReturn = await this.authorizedApiCall<PaymentMethodResponse>(
      `${baseUrl}/billing/payment-method`,
      "post",
      {
        nonce: nonce,
      }
    )
    return apiReturn
  }

  devAuthToken = async (): Promise<AxiosResponse<AuthTokenResponse>> => {
    const apiReturn = await this.authorizedApiCall<AuthTokenResponse>(
      `${baseUrl}/account/auth-token`,
      "get",
      ""
    )
    return apiReturn
  }

  register = async (userName: string, password: string, orgName: string) => {
    const registerData = {
      password: password,
      email: userName,
      orgName: orgName,
    }
    const apiReturn = await this.genericApiCall<RegisterResponse>(
      `${baseUrl}/account/register`,
      "post",
      "",
      registerData
    )
    return apiReturn
  }

  usageData = async (): Promise<AxiosResponse<UsageResponse>> => {
    const apiReturn = await this.authorizedApiCall<UsageResponse>(
      `${baseUrl}/org/usage`,
      "get",
      ""
    )
    return apiReturn
  }
}

const api = new Api()
export default api
