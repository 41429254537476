import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core"
import LinearProgress from "@material-ui/core/LinearProgress"
import React, { FC } from "react"
import { OrgUsage } from "../../../types/types"
import { Card } from "../../../utils/theme"

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const date = new Date()
const month = date.getMonth()
const year = date.getFullYear()

const getDaysInMonth = function (month: number, year: number) {
  return new Date(year, month, 0).getDate()
}
const lastDay = getDaysInMonth(month, year)

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 2,
    },
    colorPrimary: {
      backgroundColor: "#D7E9FF",
    },
    bar: {
      borderRadius: 2,
      backgroundColor: "#73B3FF",
    },
  })
)(LinearProgress)

function percentage(usage: number, total: number) {
  const result = (usage / total) * 100
  return result
}

interface QuotaProps {
  orgUsage: OrgUsage
}

const Quota: FC<QuotaProps> = ({ orgUsage }) => {
  const classes = useStyles()
  const avUsage = orgUsage.audioUnitsUsed + orgUsage.videoUnitsUsed
  const avPercent = percentage(avUsage, orgUsage.avUnitsLimit)
  const textPercent = percentage(
    orgUsage.textUnitsUsed,
    orgUsage.textUnitsLimit
  )

  return (
    <>
      <div className={classes.card}>
        <div className={classes.upperData}>
          <div className={classes.title}>Quota Usage</div>
          <div className={classes.month}>
            {monthNames[month]} 1 - {monthNames[month]} {lastDay}
          </div>
        </div>
        <div className={classes.used}>
          Audio/Video: {avUsage.toLocaleString()} of{" "}
          {orgUsage.avUnitsLimit.toLocaleString()} Units used
          {orgUsage.avUnitsLimit ? ` (${avPercent.toFixed(0)}%)` : null}.
        </div>
        <div className={classes.root}>
          <BorderLinearProgress variant="determinate" value={avPercent} />
        </div>
        <div className={classes.used}>
          Text: {orgUsage.textUnitsUsed.toLocaleString()} of{" "}
          {orgUsage.textUnitsLimit.toLocaleString()} Units used
          {orgUsage.textUnitsLimit ? ` (${textPercent.toFixed(0)}%)` : null}.
        </div>
        <div className={classes.root}>
          <BorderLinearProgress variant="determinate" value={textPercent} />
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      fontSize: 32,
      fontStyle: "normal",
      float: "left",
    },
    root: {
      width: "100%",
      float: "left",
    },
    card: Card,

    upperData: {
      width: "100%",
    },
    month: {
      color: "#6A6068",
      float: "right",
      paddingTop: 10,
      display: "none",
    },
    used: {
      color: "#3A5A80",
      fontSize: 16,
      paddingTop: 70,
    },
    hide: {
      display: "none",
    },
  })
)

export default Quota
