import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Theme,
  withStyles,
} from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React from "react"
import { UsageDetail } from "../../../types/types"
import { colors } from "../../../utils/theme"
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#F9F9FB",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#3A5A80",
      backgroundColor: "white",
    },
  })
)(TableRow)

function createData(
  name: string,
  AnalysisStarted: string,
  Units: number,
  Type: string
) {
  return { name, AnalysisStarted, Units, Type }
}

function trucName(name: string, trunc: number) {
  return name
}

function createRows(data: UsageDetail[]) {
  const newRows = data.map(rowItem => {
    let timeStartStringTrunc = rowItem.startedAt.split(".")

    const name = rowItem.media.title
      ? ` - ${trucName(rowItem.media.title, 30)}`
      : ""
    return createData(
      `${rowItem.media.id}${name}`,
      timeStartStringTrunc[0].replace("T", " "),
      rowItem.usageUnits,
      rowItem.media.type
    )
  })
  return newRows
}

interface FormattedRow {
  name: {} | null | undefined
  AnalysisStarted:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined
  Units:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined
  Type:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined
}

const AnalysisTable = (analysisTableData: any) => {
  const tableData = analysisTableData.data
  const dataLegnth = tableData.length
  const rows = createRows(tableData)
  const styles = useStyles()
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={styles.table} aria-label="customized table">
          <TableHead className={styles.tableHead}>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Analysis Started</StyledTableCell>
              <StyledTableCell align="right">Units</StyledTableCell>
              <StyledTableCell align="right">Type</StyledTableCell>
            </TableRow>
          </TableHead>
          {dataLegnth === 0 ? (
            <div className={styles.noVideosMessage}>
              You haven't analyzed any media yet.
            </div>
          ) : (
            <TableBody className={styles.tableBody}>
              {rows.map((row: FormattedRow, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.AnalysisStarted}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.Units}</StyledTableCell>
                  <StyledTableCell align="right">{row.Type}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    table: {
      paddingTop: 20,
      paddingBottom: 20,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#F9F9FB",
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: colors.neutral.grey,
      padding: theme.spacing(4),
    },
    tableHead: {},
    tableBody: {},
    noVideosMessage: {
      paddingTop: 30,
      paddingBottom: 30,
      textAlign: "center",
    },
  })
)

export default AnalysisTable
