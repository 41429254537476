import { createStyles, makeStyles } from "@material-ui/core"
import React, { FC } from "react"
import { Check, Info } from "react-feather"
import { useRecoilState } from "recoil"
import styled from "styled-components"
import {
  BillingAccountState,
  BillingErrorStates,
  SUPPORT_EMAIL,
} from "../../constants"
import { orgState } from "../../store"
import { DefaultWrapper } from "../../utils/theme"
import AlertBanner from "../atoms/AlertBanner/AlertBanner"
import { Card, CardChip, CardContent, CardTitle } from "../atoms/Card/Card"
import BootstrapTooltip from "../atoms/Tooltip/Tooltip"
import BrainTree from "../molecules/BrainTree/BrainTree"
import Navigation from "../organisms/Navigation/NavigationSide"

const Units = styled.div`
  margin-top: 16px;
`

const TrialInfo = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #f3f3f7;
  border-radius: 8px;
`

const CreatorPlanCard: FC<{ accountState: string; inTrial?: boolean }> = ({
  accountState,
  inTrial,
}) => {
  const classes = useStyles()
  inTrial = inTrial ?? false
  return (
    <Card>
      <CardTitle>
        Creator Plan <CardChip>{accountState}</CardChip>
      </CardTitle>
      <CardContent>
        <div>Billed monthly at $99/month.</div>
        {inTrial ? (
          <TrialInfo>
            <div>
              You won’t be invoiced until you have used all of your trial quota.
            </div>
            <div>
              Your trial quota includes 50 text units and 5 video units.
            </div>
          </TrialInfo>
        ) : null}
        <Units>
          <div className={classes.units}>
            Units Included{" "}
            <BootstrapTooltip
              title={
                <React.Fragment>
                  <Check className={classes.icon} />1 video unit equals 1 hour
                  of video.
                  <br />
                  <Check className={classes.icon} />1 text unit equals 250
                  words.
                </React.Fragment>
              }
            >
              <Info className={classes.icon} />
            </BootstrapTooltip>
          </div>
          <div className={classes.unitsRow}>
            <span className={classes.units}>
              <Check className={classes.icon} color={iconGreen} /> 400 text
              units
            </span>
            <span className={classes.units}>
              <Check className={classes.icon} color={iconGreen} /> 10 video
              units
            </span>
          </div>
        </Units>
      </CardContent>
    </Card>
  )
}

const CustomPlanCard: FC<{ accountState: string }> = ({ accountState }) => {
  const classes = useStyles()
  return (
    <Card>
      <CardTitle>
        Custom Plan <CardChip>{accountState}</CardChip>
      </CardTitle>
      <CardContent>
        <div>
          To update your payment information or cancel your subscription,
          contact support.
        </div>
        <div className={classes.supportLink}>
          <a href={`mailto:${SUPPORT_EMAIL}`}>Contact Support</a>
        </div>
      </CardContent>
    </Card>
  )
}

const PaymentMethodCard: FC = () => {
  const classes = useStyles()
  return (
    <Card>
      <CardTitle>Payment Method</CardTitle>
      <CardContent>
        <BrainTree />
        <div>
          To update your payment information or cancel your subscription,
          contact support.
        </div>
        <div className={classes.supportLink}>
          <a href={`mailto:${SUPPORT_EMAIL}`}>Contact Support</a>
        </div>
      </CardContent>
    </Card>
  )
}

const AddPaymentMethodCard: FC = () => {
  return (
    <Card>
      <CardTitle>Add Payment Method</CardTitle>
      <CardContent>
        <BrainTree />
      </CardContent>
    </Card>
  )
}

const getCards = (plan: string, state: string) => {
  if (plan === "custom") {
    return <CustomPlanCard accountState={state} />
  } else if (plan === "tier-1") {
    if (state === BillingAccountState.ACTIVE) {
      return (
        <>
          <CreatorPlanCard accountState={state} />
          <PaymentMethodCard />
        </>
      )
    } else if (state === BillingAccountState.INACTIVE) {
      return (
        <>
          <CreatorPlanCard accountState={state} />
          <PaymentMethodCard />
        </>
      )
    }
  } else if (plan === "None" && state === "trial") {
    return (
      <>
        <CreatorPlanCard accountState={state} inTrial={true} />
        <PaymentMethodCard />
      </>
    )
  } else {
    return (
      <>
        <AlertBanner mode="info">
          Enter a method of payment to activate your plan and start using
          ContextualPlatform
        </AlertBanner>
        <AddPaymentMethodCard />
        <CreatorPlanCard accountState={state} inTrial={true} />
      </>
    )
  }
}

const Payment: FC = () => {
  const [org, setOrg] = useRecoilState(orgState)
  const styles = useStyles()

  const billingAccountState = org?.billingAccount?.state ?? "None"
  const billingAccountPlan = org?.billingAccount?.plan ?? "None"

  const showBillingErrorBanner = BillingErrorStates.includes(
    org?.billingAccount?.state ?? ""
  )

  return (
    <div className={styles.wrapper}>
      <Navigation />
      {showBillingErrorBanner ? (
        <AlertBanner mode="error">
          There was an issue with your payment method.
        </AlertBanner>
      ) : null}
      <div>{getCards(billingAccountPlan, billingAccountState)}</div>
    </div>
  )
}

const iconGreen = "#19B66C"

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: DefaultWrapper,
    icon: {
      width: "16px",
      height: "16px",
      margin: "0 4px",
    },
    unitsRow: {
      display: "flex",
      gap: "20px",
    },
    units: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
    },
    supportLink: {
      marginTop: "10px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      "& a": {
        textDecoration: "none",
      },
    },
  })
)

export default Payment
