import { atom, selector } from "recoil"
import { LoginData, Org, PaymentMethodResponse, STORAGE } from "./types/types"
import api, { getCookieObjectOrNone } from "./utils/api"

export enum AtomKey {
  ORG = "org",
  SESSION = "Session",
  BOOTSTRAPPED = "bootstrapped",
  USER = "user",
  AUTH = "auth",
  PAYMENT_METHOD = "paymentMethod",
}

export const orgState = atom<Org | undefined>({
  key: AtomKey.ORG,
  default: selector({
    key: "org-state-default",
    get: async ({ get }) => {
      const session = get(sessionState)
      if (session) {
        const response = await api.getAccount()
        if (response.status === 200) {
          return response.data.org
        }
      }
      return undefined
    },
  }),
})

export const paymentMethodState = atom<PaymentMethodResponse | undefined>({
  key: AtomKey.PAYMENT_METHOD,
  default: selector({
    key: "payment-method-state-default",
    get: async ({ get }) => {
      const session = get(sessionState)
      if (session) {
        const response = await api.getPaymentMethod()
        if (response.status === 200) {
          return response.data
        }
      }
      return undefined
    },
  }),
})

export const bootstrapped = atom<boolean>({
  key: AtomKey.BOOTSTRAPPED,
  default: false,
})

export const sessionState = atom({
  key: AtomKey.SESSION,
  default: selector({
    key: "session-state-default",
    get: async () =>
      getCookieObjectOrNone<LoginData | null>(STORAGE.LOGIN_DATA),
  }),
})
