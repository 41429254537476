import config from "./config.json"

export const baseUrl = `${config.API_PROTOCOL}://${config.API_URL}`
export const useSentryLogger: boolean = config.USE_SENTRY_LOGGER === "true"
export const SENTRY_PROJECT_TAG = config.SENTRY_PROJECT_TAG
export const SUPPORT_EMAIL = "support@dbmoai.com"
export const ERROR_SUPPORT_TEXT = `If this error persist please contact support at ${SUPPORT_EMAIL}.`
export const TOKEN_EXPIRED_MESSAGE = "Email Verification Failed. Token expired"

export const JWT_ACCESS_TOKEN_EXPIRED_ERROR_MESSAGE =
  "JwtAccessTokenAuthGuard: jwt expired"
export const JWT_REFRESH_TOKEN_EXPIRED_ERROR_MESSAGE =
  "JWT refresh token has expired."
export const INVALID_JWT_MESSAGE_CODE = "InvalidJwtToken"
export const BRAINTREE_DO_NOT_HONOR = "Do Not Honor"

export enum UserCreationState {
  UNAPPROVED = "unapproved",
  APPROVED = "approved",
}

export enum MediaType {
  VIDEO = "video",
  AUDIO = "audio",
}

export enum LoginState {
  NOT_REGISTERED,
  NOT_APPROVED,
  SUCCESS,
  UNKNOWN,
}

export enum BillingAccountState {
  TRIAL = "trial",
  ACTIVE = "active",
  AT_LIMIT = "at-limit",
  INACTIVE = "inactive",
  PAST_DUE = "past-due",
  PAST_DUE_INVALID_CC = "past-due-invalid-cc",
  NO_BILLING_ACCOUNT = "no-billing-account",
}

export const BillingErrorStates: string[] = [
  BillingAccountState.INACTIVE,
  BillingAccountState.PAST_DUE,
  BillingAccountState.PAST_DUE_INVALID_CC,
]

export const userIdKey = "dbmo-userId"
export const accessTokenKey = "dbmo-accessToken"
export const accessTokenExpKey = "dbmo-accessTokenExp"
export const refreshTokenKey = "dbmo-refreshToken"
export const refreshTokenExpKey = "dbmo-refreshTokenExp"
export const refreshTokenIntervalInMilliseconds = 15 * 60 * 1000
