import * as Sentry from "@sentry/react"
import { AxiosResponse } from "axios"
import { ERROR_SUPPORT_TEXT } from "../constants"

export const apiErrorAlert = (
  message: string,
  context?: AxiosResponse | Record<string, any>
) => {
  const err = `message: ${message}, Error: ${JSON.stringify(context)}`
  console.error(err)
  Sentry.captureMessage(err)
  window.alert(`${message} ${ERROR_SUPPORT_TEXT}`)
}
