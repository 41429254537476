import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import Header from "../organisms/Header/Header"

// prettier-ignore
const TOS = () => {
  const classes = useStyles()
  return (
    <div>
      <Header />
      <div className={classes.tos}>
      <h1>Terms of Service</h1>
        <div className={classes.terms}>
        <p>This Agreement is entered into by DBMO and the entity or person agreeing to these terms
        ("Customer") and govern Customer's access to and use of the Services.</p>
        <p>This Agreement is effective when Customer clicks to accept it (the "Effective Date"). If you are
        accepting on behalf of Customer, you represent and warrant that (i) you have full legal authority
        to bind Customer to this Agreement; (ii) you have read and understand this Agreement; and (iii)
        you agree, on behalf of Customer, to this Agreement.</p>
        <p>The Service is not targeted towards, nor intended for use, by anyone under the age of 16. If you
        are between the ages of 16 and 18, you may use the Services only under the supervision of a
        parent or legal guardian who agrees to be bound by this Agreement.</p>
         </div>
        <div className={classes.terms}>
          <h2>1. Provision of the Services.</h2>
          <p>1.1 Services Use. During the Term, DBMO will provide the Services in accordance with the
          Agreement, including the SLAs, and Customer may use and integrate the Services in
          accordance with the Agreement.</p>
          <p>1.2 Admin Console. Customer will have access to the Admin Console, through which Customer
          may manage its use of the Services.</p>
          <p>1.3 Modifications.</p>
          <p>(a) To the Services. DBMO may make commercially reasonable updates to the Services from
          time to time. DBMO will inform Customer if DBMO makes a material change to the Services that
          has a material impact on Customer's use of the Services.</p>
          <p>(b) To the Agreement. DBMO may make changes to this Agreement, including pricing (and any
          linked documents) from time to time. Unless otherwise noted by DBMO, material changes to the
          Agreement will become effective 30 days after they are posted, except to the extent the
          changes apply to new functionality in which case they will be effective immediately. If Customer
          does not agree to the revised Agreement, Customer may stop using the Services. Customer
          may also terminate this Agreement for convenience under Section 8.4 (Termination for
          Convenience). Customer's continued use of the Services after such material change will
          constitute Customer's consent to such changes.</p>
          <p>(c) Discontinuation of Services. DBMO will notify Customer at least 30 days before discontinuing
          any Service (or associated material functionality) unless DBMO replaces such discontinued
          Service or functionality with a materially similar Service or functionality. Nothing in this Section
          1.4(c) (Discontinuation of Services) limits DBMO’s ability to make changes required to comply
          with applicable law, address a material security risk, or avoid a substantial economic or material
          technical burden.</p>
          <p>1.4 Software. DBMO may make Software available to Customer, including third-party software.
          Customer's use of any Software is subject to the applicable provisions in the Service Specific
          Terms.</p>
        </div>
        <div className={classes.terms}>
          <h2>2. Payment Terms</h2>
          <p>2.1 Online Billing. At the end of the applicable Fee Accrual Period DBMO will issue an electronic
          bill to Customer for all charges based on Customer's use of the Services during the applicable
          Fee Accrual Period (including, if applicable, the relevant Fees for TSS). Customer will pay all
          Fees in the currency stated in the invoice. If Customer elects to pay by credit card, debit card, or
          other non-invoiced form of payment, DBMO will charge (and Customer will pay) all Fees
          immediately at the end of the Fee Accrual Period. If Customer elects to pay by invoice (and
          DBMO agrees), all Fees are due as stated in the invoice. Customer's obligation to pay all Fees
          is non-cancellable. DBMO’s measurement of Customer's use of the Services is final. DBMO has
          no obligation to provide multiple bills. Payments made via wire transfer must include the bank
          information provided by DBMO.</p>
          <p>2.2 Taxes.</p>
          <p>(a) Customer is responsible for any Taxes, and will pay DBMO for the Services without any
          reduction for Taxes. If DBMO is obligated to collect or pay any Taxes, the Taxes will be invoiced
          to Customer and Customer will pay such Taxes to DBMO, unless Customer provides DBMO
          with a timely and valid tax exemption certificate in respect of those Taxes.</p>
          <p>(b) Customer will provide DBMO with any applicable tax identification information that DBMO
          may require under applicable law to ensure its compliance with applicable tax regulations and
          authorities in applicable jurisdictions. Customer will be liable to pay (or reimburse DBMO for)
          any taxes, interest, penalties, or fines arising out of any mis-declaration by Customer.</p>
          <p>2.3 Payment Disputes & Refunds. Any payment disputes must be submitted before the payment
          due date. Refunds (if any) are at DBMO’s discretion. Nothing in this Agreement obligates DBMO
          to extend credit to any party.</p>
          <p>2.4 Delinquent Payments; Suspension. Late payments may bear interest at the rate of 1.5% per
          month (or the highest rate permitted by law, if less) from the payment due date until paid in full.
          Customer will be responsible for all reasonable expenses (including attorneys' fees) incurred by
          DBMO in collecting such delinquent amounts. Further, if Customer's payment for the Services is
          overdue, DBMO may Suspend the Services.</p>
          <p>2.5 No Purchase Order Number Required. Customer is obligated to pay all applicable Fees
          without any requirement for DBMO to provide a purchase order number on DBMO’s invoice (or
          otherwise).</p>
        </div>
        <div className={classes.terms}>
          <h2>3. Customer Obligations.</h2>
          <p>3.1 Compliance. Customer will (a) ensure that Customer and its End Users' use of the Services
          complies with the Agreement, (b) use commercially reasonable efforts to prevent and terminate
          any unauthorized use of, or access to, the Services, and (c) promptly notify DBMO of any
          unauthorized use of, or access to, the Services, Account, or Customer's password of which
          Customer becomes aware. DBMO reserves the right to investigate any issue or potential
          violation of this agreement by Customer, which may include reviewing Customer Applications,
          Customer Data, or Projects</p>
          <p>3.2 Privacy. Customer is responsible for any consents and notices required to permit (a)
          Customer's use and receipt of the Services and (b) DBMO’s accessing, storing, and processing
          of data provided by Customer (including Customer Data, if applicable) under the Agreement.</p>
          <p>3.3 Restrictions. Customer will not, and will not allow End Users to, (a) copy, modify, or create a
          derivative work of the Services; (b) reverse engineer, decompile, translate, disassemble, or
          otherwise attempt to extract any or all of the source code of, the Services (except to the extent
          such restriction is expressly prohibited by applicable law); (c) sell, resell, sublicense, transfer, or
          distribute any or all of the Services; or (d) access or use the Services (i) for High Risk Activities;
          (ii) in a manner intended to avoid incurring Fees (including creating multiple Customer
          Applications, Accounts, or Projects to simulate or act as a single Customer Application, Account,
          or Project (respectively)) or to circumvent Service-specific usage limits or quotas; (iii) to
          transmit, store, or process health information subject to United States HIPAA regulations</p>
          <p>3.5 Copyright. DBMO cannot determine whether something is being used legally without input
          from the copyright holders. It is the Customer’s obligation to ensure that it can pass any content
          to DBMO. DBMO will respond to notices of alleged copyright infringement and may terminate
          repeat infringers in appropriate circumstances as required to maintain safe harbor for online
          service providers under the U.S. Digital Millennium Copyright Act.</p>
          <p>3.6 Nature of Content. It is the Customer’s obligation to ensure that it holds the proper rights to
          have content analyzed by DBMO, and that the content is legal. Note, DBMO cannot screen
          content submitted for analysis prior to analysis, and cannot determine legality even after
          analysis. DBMO may refuse to process any content that it deems potentially illegal or obscene,
          or further content from a Customer that has submitted such content.</p>
        </div>
        <div className={classes.terms}>
          <h2>4. Suspension</h2>
          <p>4.1 DBMO may immediately Suspend all or part of Customer's use of the Services if (a) DBMO
          reasonably believes Customer's or any End User's use of the Services could adversely impact
          the Services, other customers' or their end users' use of the Services, or servers used to provide
          the Services; (b) there is suspected unauthorized third-party access to the Services; (c) DBMO
          reasonably believes that immediate Suspension is required to comply with any applicable law;
          or (d) Customer is in breach of Section 3 or the Service Specific Terms.</p>
        </div>
        <div className={classes.terms}>
          <h2>5. Intellectual Property Rights</h2>
          <p>5.1 Intellectual Property Rights. Except as expressly stated in this Agreement, this Agreement
          does not grant either party any rights, implied or otherwise, to the other's content or any of the
          other's intellectual property. As between the parties, Customer owns all Intellectual Property
          Rights in Customer Data and Customer Applications, and DBMO owns all Intellectual Property
          Rights in the Services and Software. DBMO may provide Documentation for Customer's use of
          the Services and retains all Intellectual Property Rights to that Documentation.</p>
        </div>
        <div className={classes.terms}>
          <h2>6. Confidential Information.</h2>
          <p>6.1 Obligations. DBMO will only use Customer's Confidential Information to exercise Customer’s
          rights and fulfill its obligations under the Agreement, and will use reasonable care to protect
          against the disclosure of the disclosing party's Confidential Information. The recipient may
          disclose Confidential Information only to its Affiliates, employees, agents, or professional
          advisors ("Delegates") who need to know it and who have agreed in writing (or in the case of
          professional advisors are otherwise bound) to keep it confidential. The recipient will ensure that
          its Delegates use the received Confidential Information only to exercise rights and fulfill
          obligations under this Agreement.</p>
          <p>6.2 Required Disclosure. Notwithstanding any provision to the contrary in this Agreement, the
          recipient or its Affiliate may also disclose Confidential Information to the extent required by
          applicable Legal Process; provided that the recipient or its Affiliate uses commercially
          reasonable efforts to (a) promptly notify the other party before any such disclosure of its
          Confidential Information, and (b) comply with the other party's reasonable requests regarding its
          efforts to oppose the disclosure. Notwithstanding the foregoing, subsections (a) and (b) above
          will not apply if the recipient determines that complying with (a) and (b) could (i) result in a
          violation of Legal Process; (ii) obstruct a governmental investigation; or (iii) lead to death or
          serious physical harm to an individual.</p>
        </div>
        <div className={classes.terms}>
          <h2>7. Term and Termination</h2>
          <p>7.1 Agreement Term. The term of this Agreement (the "Term") will begin on the Effective Date
          and continue until the Agreement is terminated as stated in this Section 7 (Term and
          Termination).</p>
          <p>7.2 Termination for Breach. To the extent permitted by applicable law, either party may terminate
          this Agreement immediately on written notice if (a) the other party is in material breach of the
          Agreement and fails to cure that breach within 30 days after receipt of written notice of the
          breach or (b) the other party ceases its business operations or becomes subject to insolvency
          proceedings and the proceedings are not dismissed within 90 days.</p>
          <p>7.3 Termination for Convenience. Customer may stop using the Services at any time. Customer
          may terminate this Agreement for its convenience at any time on prior written notice and, upon
          termination, must cease use of the applicable Services. DBMO may terminate this Agreement
          for its convenience at any time with 30 days' prior written notice to Customer.</p>
          <p>7.4 Termination Due to Applicable Law; Violation of Laws. DBMO may terminate this Agreement
          immediately on written notice if DBMO reasonably believes that continued provision of any
          Service used by Customer would violate applicable law(s) or Customer has violated or caused
          DBMO to violate any law(s).</p>
          <p>7.5 Effect of Termination. If the Agreement is terminated, then (a) all rights and access to the
          Services will terminate (including access to Customer Data, if applicable), unless otherwise
          described in this Agreement, and (b) all Fees owed by Customer to DBMO are immediately due
          upon Customer’s receipt of the final electronic bill or as stated in the final invoice.</p>
        </div>
        <div className={classes.terms}>
          <h2>8. Publicity.</h2>
          <p>Customer may state publicly that it is a DBMO customer and display DBMO Brand
          Features. DBMO may use Customer's name and Brand Features in online or offline promotional
          materials of the Services.</p>
        </div>
        <div className={classes.terms}>
          <h2>9. Representations and Warranties</h2>
          <p>Each party represents and warrants that (a) it has full
          power and authority to enter into the Agreement, and (b) it will comply with all laws applicable to
          its provision, receipt, or use of the Services, as applicable.</p>
        </div>
        <div className={classes.terms}>
          <h2>10. Disclaimer.</h2>
          <p>Except as expressly provided for in the Agreement, DBMO does not make and
          expressly disclaims to the fullest extent permitted by applicable law (a) any warranties of any
          kind, whether express, implied, statutory, or otherwise, including warranties of merchantability,
          fitness for a particular use, title, noninfringement, or error-free or uninterrupted use of the
          Services or Software and (b) any representations about content or information accessible
          through the Services.</p>
        </div>
        <div className={classes.terms}>
          <h2>11. Limitation of Liability.</h2>
          <p>11.1 Limitation on Indirect Liability. To the extent permitted by applicable law and subject to
          Section 11.3 (Unlimited Liabilities), neither party will have any Liability arising out of or relating to
          the Agreement for any (a) indirect, consequential, special, incidental, or punitive damages or (b)
          lost revenues, profits, savings, or goodwill.</p>
          <p>11.2 Limitation on Amount of Liability. Each party's total aggregate Liability for damages arising
          out of or relating to the Agreement is limited to the Fees Customer paid during the 12 month
          period before the event giving rise to Liability.</p>
          <p>11.3 Unlimited Liabilities. Nothing in the Agreement excludes or limits either party's Liability for:</p>
          <p>(a) its fraud or fraudulent misrepresentation;</p>
          <p>(b) its obligations under Section 13 (Indemnification);</p>
          <p>(c) its infringement of the other party's Intellectual Property Rights;</p>
          <p>(d) its payment obligations under the Agreement; or</p>
          <p>(e) matters for which liability cannot be excluded or limited under applicable law</p>
          <p>(f) CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
          THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
          YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY
          NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>
        </div>
        <div className={classes.terms}>
          <h2>12. Indemnification.</h2>
          <p>12.1 You agree to defend, indemnify and hold harmless DBMO and its affiliates and subsidiaries
          (collectively, the "Indemnified Parties"), and the Indemnified Parties' independent contractors,
          service providers and consultants, and their respective directors, employees and agents, from
          and against any claims, damages, costs, liabilities and expenses (including reasonable
          attorneys' fees) arising out of or related to any content you transmit on or through the Services,
          your use of or inability to use the Services, or the licensing of your content to third parties
          through the Services, including any actual or threatened suit, demand or claim made against
          any of the Indemnified Parties and/or their independent contractors, service providers,
          employees, directors or consultants, arising out of or relating to your content, your conduct, your
          violation of this Agreement or your violation of the rights of any third party.</p>
        </div>
        <div className={classes.terms}>
          <h2>14. Miscellaneous.</h2>
          <p>14.1 Notices. Under the Agreement, notices to Customer must be sent to the email address
          used for registration and notices to DBMO must be sent to outreach@dbmo.ai. Notice will be
          treated as received when the email is sent. Customer is responsible for keeping its Notification
          Email Address current throughout the Term.</p>
          <p>14.2 Emails. The parties may use emails to satisfy written approval and consent requirements
          under the Agreement.</p>
          <p>14.3 Assignment. Neither party may assign any part of this Agreement without the written
          consent of the other, except to an Affiliate where (a) the assignee has agreed in writing to be
          bound by the terms of this Agreement, and (b) the assigning party has notified the other party of
          the assignment. Any other attempt to assign is void.</p>
          <p>14.4 Change of Control. If a party experiences a change of Control other than as part of an
          internal restructuring or reorganization (for example, through a stock purchase or sale, merger,
          or other form of corporate transaction), that party will give written notice to the other party within
          30 days after the change of Control.</p>
          <p>14.5 Force Majeure. Neither party will be liable for failure or delay in performance to the extent
          caused by circumstances beyond its reasonable control, including acts of God, natural
          disasters, terrorism, riots, or war.</p>
          <p>14.6 Subcontracting. DBMO may subcontract obligations under the Agreement but will remain
          liable to Customer for any subcontracted obligations.</p>
          <p>14.7 No Agency. This Agreement does not create any agency, partnership, or joint venture
          between the parties.</p>
          <p>14.8 No Waiver. Neither party will be treated as having waived any rights by not exercising (or
          delaying the exercise of) any rights under this Agreement.</p>
          <p>14.9 Severability. If any part of this Agreement is invalid, illegal, or unenforceable, the rest of the
          Agreement will remain in effect.</p>
          <p>14.10 No Third-Party Beneficiaries. This Agreement does not confer any benefits on any third
          party unless it expressly states that it does.</p>
          <p>14.11 Equitable Relief. Nothing in this Agreement will limit either party's ability to seek equitable
          relief.</p>
          <p>14.12 U.S. Governing Law. All claims will be governed by New York law, and will be litigated
          exclusively in Queens County or New York County, New York, USA.</p>
          <p>14.13 International Users. DBMO is located in New York State in the United States of America.
          If you access the Service from a country other than the United States, you agree that your
          transactions with DBMO occur in the United States. You are responsible for compliance with all
          applicable laws, rules and regulations applicable to your use of the Service.</p>
          <p>14.14 Amendments. Except as stated in Section 1.4(b) (Modifications: To the Agreement), any
          amendment must be in writing, signed by both parties, and expressly state that it is amending
          this Agreement.</p>
          <p>14.15 Survival. The following Sections will survive expiration or termination of this Agreement:
          Section 2 (Payment Terms), Section 5 (Intellectual Property Rights; Protection of Customer
          Data; Feedback), Section 7 (Confidential Information), Section 8.6 (Effect of Termination),
          Section 11 (Disclaimer), Sect</p>
          <p>14.16 Entire Agreement. This Agreement sets out all terms agreed between the parties and
          supersedes all other agreements between the parties relating to its subject matter. In entering
          into this Agreement, neither party has relied on, and neither party will have any right or remedy
          based on, any statement, representation, or warranty (whether made negligently or innocently),
          except those expressly stated in this Agreement. The URL Terms are incorporated by reference
          into the Agreement. After the Effective Date, DBMO may provide an updated URL in place of
          any URL in this Agreement.</p>
          <p>14.19 Definitions.</p>
          <p><strong>"Account"</strong> means Customer's account for DBMO’s ContextualPlatform.</p>
          <p><strong>"Admin Console"</strong> means the online console(s) or dashboard provided by DBMO to Customer
          for administering the Services.</p>
          <p><strong>"Affiliate"</strong> means any entity that directly or indirectly Controls, is Controlled by, or is under
          common Control with a party.</p>
          <p><strong>"Brand Features"</strong> means the trade names, trademarks, service marks, logos, domain names,
          and other distinctive brand features of each party, respectively, as secured by such party from
          time to time.</p>
          <p><strong>"Confidential Information"</strong> means information that one party (or an Affiliate) discloses to the
          other party under this Agreement, and which is marked as confidential or would normally under
          the circumstances be considered confidential information. It does not include information that is
          independently developed by the recipient, is rightfully given to the recipient by a third party
          without confidentiality obligations, or becomes public through no fault of the recipient. Subject to
          the preceding sentence, Customer Data is considered Customer's Confidential Information.</p>
          <p>"Control" <strong>"Control"</strong> means control of greater than 50 percent of the voting rights or equity interests of a
          party.</p>
          <p><strong>"Customer Application"</strong> means a software program that Customer creates or hosts using the
          Services</p>
          <p><strong>"Customer Data"</strong> means data provided to DBMO by Customer or End Users through the
          Services under the Account.</p>
          <p><strong>"Documentation"</strong> means the documentation (as may be updated from time to time) in the form
          generally made available by DBMO to its customers for use with the Services</p>
          <p><strong>"End Users"</strong> means the individuals who are permitted by Customer to use the Services. For
          clarity, End Users may include employees of Customer Affiliates and other authorized third
          parties.</p>
          <p><strong>"Fee Accrual Period"</strong> means a calendar month or another period specified by DBMO in the
          Admin Console.</p>
          <p><strong>"Fees"</strong> means the applicable fees for each Service or Software plus any applicable Taxes.</p>
          <p><strong>"High Risk Activities"</strong> means activities where the use or failure of the Services would
          reasonably be expected to lead to death, personal injury, or environmental or property damage
          (such as the creation or operation of nuclear facilities, air traffic control, life support systems, or
          weaponry).</p>
          <p><strong>"HIPAA"</strong> means the Health Insurance Portability and Accountability Act of 1996 as it may be
          amended from time to time, and any regulations issued under it.
          "including" means including but not limited to.</p>
          <p><strong>"Indemnified Liabilities"</strong> means any (i) settlement amounts approved by the indemnifying
          party and (ii) damages and costs finally awarded against the indemnified party by a court of
          competent jurisdiction</p>
          <p><strong>"Intellectual Property Rights"</strong> means current and future worldwide rights under patent,
          copyright, trade secret, trademark, and moral rights laws, and other similar rights.</p>
          <p><strong>"Legal Process"</strong> means an information disclosure request made under law, governmental
          regulation, court order, subpoena, warrant, or other valid legal authority, legal procedure, or
          similar process.</p>
          <p><strong>"Liability"</strong> means any liability, whether under contract, tort (including negligence), or otherwise,
          regardless of whether foreseeable or contemplated by the parties.</p>
          <p><strong>"Notification Email Address"</strong> means the email address(es) designated by Customer in the
          Admin Console.</p>
          <p><strong>"Services"</strong> means the services comprised of ContextualPlatform provided by DBMO</p>
          <p><strong>"SLA"</strong> means each of the then-current service level agreements (Exhibit I).</p>
          <p><strong>"Software"</strong> means any downloadable tools, software development kits, or other such computer
          software provided by DBMO in connection with the Services, and any updates DBMO may
          make to such Software from time to time, excluding any Third-Party Offerings.</p>
          <p><strong>"Suspend"</strong> or "Suspension" means disabling or limiting access to or use of the Services or
          components of the Services.</p>
          <p><strong>"Taxes"</strong> means all government-imposed taxes, except for taxes based on Google's net income,
          net worth, asset value, property value, or employment.</p>
          <p><strong>"Term"</strong> has the meaning stated in Section 7.1 (Agreement Term) of this Agreement.</p>
          <p><strong>"Third-Party Offerings"</strong> means (a) third-party services, software, products, and other offerings
          that are not incorporated into the Services or Software and (b) offerings identified in the
          "Third-Party Terms" section of the Service Specific Terms.</p>
          <p><strong>"TSS"</strong> means the then-current technical support service provided by DBMO to Customer</p>
          <p><strong>"URL Terms"</strong> means, collectively, these terms along with SLA.</p>
        </div>
        <div className={classes.terms}>
          <h2>Exhibit I: Service Level Agreement (SLA)</h2>
          <p>Last modified: June 21, 2021</p>
          <p>During the Term of Agreement, the Service will provide uptime to the Customer of at least 99.9%
          for any given month. This calculation is exclusive of any scheduled downtime</p>
          <p>Customer is required to limit the number of requests to the DBMO API to 1 per second, unless
          otherwise agreed to in writing. Requests in excess of this rate may be refused and do not
          constitute downtime.</p>
          <p>If the Service does not achieve the uptime described in this SLA and if Customer meets its
          obligations under this agreement, Customer will be eligible to receive credit for the downtime.
          Credit must be requested by contacting DBMO support (support@dbmo.ai) within 30 days from
          the end of the month when any downtime occurred.</p>
          <p>The maximum aggregate credit issued by DBMO to Customer for all downtime in a single billing
          month will not exceed 50% of the amount due from Customer for the Service for the applicable
          month.</p>
          <p>The SLA does not apply to any: (a) features or services designated Alpha or Beta (unless
          otherwise set forth in the associated Documentation), (b) features or services excluded from the
          SLA (in the associated Documentation), or (c) errors: (i) caused by factors outside of DBMO’s
          reasonable control; (ii) that resulted from Customer’s software or hardware or third party
          software or hardware, or both; (iii) that resulted from abuses or other behaviors that violate the
          Agreement; or (iv) that resulted from quotas applied by the system or listed in the Admin
          Console.</p>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    tos: {
      marginTop: 100,
      display: "inline-grid",
      paddingLeft: 150,
      paddingRight: 150,
    },
    terms: {
      paddingTop: 25,
      paddingBottom: 25,
    },
  })
)
export default TOS
