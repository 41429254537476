import { List, ListItem, ListItemText, Toolbar } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import ArrowForward from "@material-ui/icons/ArrowForward"
import Book from "@material-ui/icons/Book"
import Explore from "@material-ui/icons/Explore"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import InsertLink from "@material-ui/icons/InsertLink"
import Mail from "@material-ui/icons/Mail"
import Payment from "@material-ui/icons/Payment"
import React from "react"
import { BrowserRouter, Link, useHistory, useLocation } from "react-router-dom"
import { useRecoilState } from "recoil"
import { baseUrl, SUPPORT_EMAIL } from "../../../constants"
import { useSessionStore } from "../../../hooks/useSessionStore"
import { orgState } from "../../../store"
import { colors } from "../../../utils/theme"
import Logo from "../../particles/Logo/Logo"

async function externalLink(externalLink: string) {
  window.open(externalLink, "_blank")
}
const drawerWidth = 240
const backgroundColor = "#F9F9FB"

const Navigation = () => {
  const { logOut } = useSessionStore()
  const [org] = useRecoilState(orgState)
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const mainLinks = ["/dashboard", "/payment", "/keys", "/examples"]
  const mainIcons = [
    <Explore className={classes.icons} />,
    <Payment className={classes.icons} />,
    <InsertLink className={classes.icons} />,
    <InsertDriveFile className={classes.icons} />,
  ]

  const isActiveLink = (index: number): boolean => {
    console.log(location.pathname)
    return location.pathname === mainLinks[index]
  }

  return (
    <div className={classes.root}>
      <BrowserRouter>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div>
              <Logo />
            </div>
            <div className={classes.orgName}>{org?.name}</div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div>
            <List>
              {[
                "Dashboard",
                "Manage Payment",
                "API Keys",
                "Get Started & Examples",
              ].map((text, index) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => history.push(mainLinks[index])}
                  className={isActiveLink(index) ? classes.active : undefined}
                >
                  {mainIcons[index]}
                  <Link to={mainLinks[index]} className={classes.links}>
                    <ListItemText primary={text} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </div>
          <Divider />
          <div className={classes.middleItems}>
            <label className={classes.externalLinksLabel}>External Links</label>
            <List>
              <ListItem
                button
                key=""
                onClick={async () => await externalLink(`${baseUrl}/docs`)}
              >
                <Book className={classes.icons} />
                <ListItemText className={classes.links} primary="API Docs" />
              </ListItem>
            </List>
          </div>
          <Divider />
          <div className={classes.lastItems}>
            <List>
              <ListItem>
                <Mail className={classes.icons} />
                <ListItemText>
                  <a href={`mailto:${SUPPORT_EMAIL}`}>Contact Support</a>
                </ListItemText>
              </ListItem>
              <ListItem button key="logout" onClick={logOut}>
                <ArrowForward className={classes.icons} />
                <ListItemText className={classes.links} primary="Log Out" />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </BrowserRouter>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: "100%",
      height: 72,
      backgroundColor: backgroundColor,
      zIndex: 1300,
      boxShadow: "0 1px 0 0 grey",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: backgroundColor,
      marginTop: 74,
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    icons: {
      color: "#6A6068",
      paddingLeft: 5,
      marginRight: 5,
    },
    // logo: {
    //   width: "100%",
    // },
    orgName: {
      float: "right",
      color: "#2F232D",
    },
    links: {
      textDecoration: "none",
    },
    active: {
      background: colors.blueBackground,
    },
    middleItems: {
      paddingBottom: "100%",
    },
    externalLinksLabel: {
      display: "block",
      paddingTop: 25,
      paddingLeft: 20,
      color: "#6A6068",
      fontStyle: "normal",
      fontFamily: "Work Sans,",
      fontWeight: "normal",
      fontSize: "large",
    },
    lastItems: {
      position: "fixed",
      bottom: 0,
    },
  })
)

export default Navigation
